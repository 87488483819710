<template>
  <v-container>
    <v-tabs class="pb-2" v-if="hasCheckedInfo" centered v-model="activeTab">
      <v-tab>RESTAURANT
      </v-tab>
      <v-tab>SPEISEKARTE
      </v-tab>
    </v-tabs>
    <div v-if="hasCheckedInfo">
      <div v-if="activeTab===0">
        <v-banner :single-line="$vuetify.breakpoint.mdAndUp"
                  :color="bannerInfos[1]">
          <v-icon
              slot="icon"
              :color="acceptingOrders ? 'green' : 'red'"
              size="36"
          >
            {{ acceptingOrders ? mdiCheck : mdiClose }}
          </v-icon>
          <h3>
            {{ bannerInfos[0] }}
          </h3>
          <template v-slot:actions>
            <v-btn
                @click="toggleRestaurantAvailability"
                text
            >
              {{ acceptingOrders ? 'Restaurant schließen' : 'Restaurant öffnen' }}
            </v-btn>
          </template>
        </v-banner>
        <v-row>
          <v-col md="6" cols="12">
            <v-col cols="12" class="mx-0 px-0">
              <v-card>
                <v-card-text>
                  <v-row>

                    <v-col cols="12">
                      <v-btn block @click="handleReloadMenu">Speisekarte neu laden</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="mx-0 px-0" v-if="showCouponSettings">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-layout>
                        <div class="title">Rabattgutscheine</div>
                        <v-spacer></v-spacer>
                        <v-btn @click="handleNewDiscountCode" icon>
                          <v-icon>{{ mdiPlus }}</v-icon>
                        </v-btn>
                      </v-layout>
                    </v-col>
                    <v-col cols="12" v-for="code in discountCodes.filter(x => x.disabled == false)" :key="code.id">
                      <v-card class="px-0 mx-0">
                        <v-card-text>
                          <v-row no-gutters>
                            <v-col cols="10">
                              <v-row no-gutters>
                                <v-col cols="8">
                                  <div>
                                    Code: {{ code.code }}
                                  </div>
                                </v-col>
                                <v-col cols="3">
                                  {{ code.minimumOrder > 0 ? 'MBW: ' + (code.minimumOrder / 100) + ' €' : 'Kein MBW' }}
                                </v-col>
                                <v-col cols="4">
                                  <div v-if="code.type=='percent'">
                                    Rabatt: {{ code.percentOff }} %
                                  </div>
                                  <div v-if="code.type=='cash'">
                                    Rabatt: {{ code.cashDiscount / 100|currency }}
                                  </div>
                                  <div v-if="code.usages">
                                    <span v-if="$vuetify.breakpoint.smAndUp">Anzahl der </span>Benutzungen: {{ code.usages.length }}
                                  </div>
                                </v-col>

                              </v-row>
                            </v-col>
                            <v-col cols="2">
                              <v-tooltip top>
                                <template v-slot:activator="{on}">
                                  <v-icon class="mx-2" v-on="on">{{ mdiInformation }}</v-icon>
                                </template>
                                <span>{{ createCodeInfoString(code) }}</span>
                              </v-tooltip>
                              <v-btn @click="disableDiscountCode(code.id)" icon>
                                <v-icon>{{ mdiDelete }}</v-icon>
                              </v-btn>
                            </v-col>
                          </v-row>

                        </v-card-text>
                      </v-card>
                    </v-col>
                    <v-col cols="12">
                    <v-expansion-panels v-if="discountCodes.filter(x => x.disabled == true).length > 0">
                      <v-expansion-panel>
                          <v-expansion-panel-header>Deaktivierte</v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-col cols="12" v-for="code in discountCodes.filter(x => x.disabled == true)" :key="code.id">
                              <v-card class="px-0 mx-0">
                                <v-card-text>
                                  <v-row no-gutters>
                                    <v-col cols="9">
                                      <v-row no-gutters>
                                        <v-col cols="8">
                                          <div>
                                            Code: {{ code.code }}
                                          </div>
                                        </v-col>
                                        <v-col cols="3">
                                          {{ code.minimumOrder > 0 ? 'MBW: ' + (code.minimumOrder / 100) + ' €' : 'Kein MBW' }}
                                        </v-col>
                                        <v-col cols="4">
                                          <div v-if="code.type=='percent'">
                                            Rabatt: {{ code.percentOff }} %
                                          </div>
                                          <div v-if="code.type=='cash'">
                                            Rabatt: {{ code.cashDiscount / 100|currency }}
                                          </div>
                                          <div v-if="code.usages">
                                            <span v-if="$vuetify.breakpoint.smAndUp">Anzahl der </span>Benutzungen: {{ code.usages.length }}
                                          </div>
                                        </v-col>

                                      </v-row>
                                    </v-col>
                                    <v-col cols="3">
                                      <v-tooltip top>
                                        <template v-slot:activator="{on}">
                                          <v-icon class="mx-2" v-on="on">{{ mdiInformation }}</v-icon>
                                        </template>
                                        <span>{{ createCodeInfoString(code) }}</span>
                                      </v-tooltip>
                                      <v-btn @click="enableDiscountCode(code.id)" icon>
                                        <v-icon>{{ mdiUndoVariant }}</v-icon>
                                      </v-btn>
                                      <v-btn @click="deleteDiscountCode(code.id)" icon>
                                        <v-icon>{{ mdiDelete }}</v-icon>
                                      </v-btn>
                                    </v-col>
                                  </v-row>

                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" class="mx-0 px-0">
              <opening-time-changer></opening-time-changer>
            </v-col>

          </v-col>
          <v-col md="6" cols="12">
            <v-col cols="12" class="mx-0 px-0">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-layout>
                      <div class="title text-center">Liefergebiete</div>
                      <v-spacer></v-spacer>
                      <v-btn icon @click="zipDialog = true; inputZipCode = {}; zipAction='add'">
                        <v-icon>{{ mdiPlus }}</v-icon>
                      </v-btn>
                    </v-layout>
                  </v-col>
                  <v-col cols="12" sm="6" v-for="zipCode in zipCodes" :key="zipCode.zipCode">
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col cols="9">
                            <div>
                              PLZ: {{ zipCode.zipCode }} <br>
                              MBW: {{ zipCode.minimumOrder / 100 |currency }} <br>
                              Liefergebühr: {{ zipCode.deliveryFee / 100 | currency }}
                            </div>
                          </v-col>
                          <v-col cols="3">
                            <v-btn @click="deleteZipCode(zipCode)" icon>
                              <v-icon>{{ mdiDelete }}</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>

                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            </v-col>
          </v-col>
        </v-row>

      </div>
      <div v-if="activeTab===1">
        <menu-editor></menu-editor>
      </div>

      <v-dialog
          v-model="zipDialog"
          max-width="800px"
          v-if="activeTab===0"
      >
        <v-card>
          <v-banner single-line
                    color='deep-orange lighten-3'
                    class="pt-1 mb-3">
            <v-icon
                slot="icon"
                color="red"
                size="36"
            >
              {{ mdiExclamation }}
            </v-icon>
            <h3>
              WICHTIG! Geben Sie die Liefergebühr/Mindestbestellwert in CENT ein.
            </h3>
          </v-banner>

          <v-card-text>
            <v-text-field
                v-model="inputZipCode.zipCode"
                type="number"
                label="Postleitzahl"
            />
            <v-text-field
                v-model.number="inputZipCode.deliveryFee"
                type="number"
                label="Liefergebühr in Cent"
                :hint="inputZipCode.deliveryFee > 0 ? inputZipCode.deliveryFee/100 + '€' : ''"
                persistent-hint
            />
            <v-text-field
                v-model.number="inputZipCode.minimumOrder"
                type="number"
                label="Mindestbestellwert in Cent"
                :hint="inputZipCode.minimumOrder > 0 ? inputZipCode.minimumOrder/100 + '€' : ''"
                persistent-hint
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text :disabled="!inputZipCode.zipCode || inputZipCode.zipCode.length != 5"
                   @click="saveZipCode(inputZipCode)">Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          :fullscreen="$vuetify.breakpoint.smAndDown"
          v-model="discountDialog"
          max-width="800px"
          v-if="activeTab===0"
      >
        <v-card>
          <v-card-title>Rabattgutschein</v-card-title>
          <v-card-text>
            <v-row align="start">
              <v-col cols="7" sm="9">
                <v-text-field
                    dense
                    v-model="inputCodeName"
                    label="Name des Codes"
                />
              </v-col>
              <v-col cols="5" sm="3">
                <v-btn text @click="generateDiscountCode">Generieren</v-btn>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-if="inputCodeType=='percent'"
                    dense
                    v-model="inputCodePercentOff"
                    type="number"
                    label="Prozent des Rabattes"
                    suffix="%"
                />
                <v-text-field
                    v-else
                    dense
                    v-model="inputCodeCashDiscount"
                    type="number"
                    persistent-hint
                    :hint="inputCodeCashDiscount > 0 ? inputCodeCashDiscount/100 + '€' : ''"
                    label="Summe des Geldrabatts in Cent"
                    suffix="Cent"
                />
              </v-col>
              <v-col cols="6">
                <v-radio-group class="mt-0" row v-model="inputCodeType">
                  <v-radio label="Prozentrabatt" value="percent"></v-radio>
                  <v-radio label="Flache Geldsumme" value="cash"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="9">
                <v-text-field
                    dense
                    type="number"
                    suffix="Cent"
                    v-model="inputCodeMinimumOrder"
                    persistent-hint
                    :hint="inputCodeMinimumOrder > 0 ? inputCodeMinimumOrder/100 + '€' : ''"
                    label="Mindestbestellwert in Cent"
                />
              </v-col>
              <v-col cols="3">
                <v-tooltip top>
                  <template v-slot:activator="{on}">
                    <v-icon class="mx-2" v-on="on">{{ mdiInformation }}</v-icon>
                  </template>
                  <span>Falls der sonstige MBW größer ist, wird dieser verwendet.</span>
                </v-tooltip>
              </v-col>
              <v-col cols="7" sm="9">
                <v-text-field
                    dense
                    :disabled="inputCodeUnlimitedUses"
                    v-model="inputCodeMaxUses"
                    type="number"
                    label="Maximale Anzahl der Nutzungen"
                />
              </v-col>
              <v-col cols="5" sm="3">
                <v-checkbox class="mt-0" dense v-model="inputCodeUnlimitedUses"
                            label="Unbegrenzt"></v-checkbox>
              </v-col>
              <v-slide-y-transition>
                <v-col cols="12" v-if="inputCodeMaxUses > 1 || inputCodeUnlimitedUses"
                       class="pt-0 mt-0">
                  <v-checkbox dense v-model="inputCodeOncePerUser"
                              label="Nur einmal pro Nutzer verwendbar" class="pt-0 mt-0"></v-checkbox>
                </v-col>
              </v-slide-y-transition>

              <v-col cols="7" sm="9">
                <date-time-picker :disabled="inputCodeNoStartTime" label="Startzeit des Gutscheins"
                                  v-model="inputCodeStartTime"></date-time-picker>
              </v-col>
              <v-col cols="5" sm="3">
                <v-checkbox class="mt-0" dense v-model="inputCodeNoStartTime"
                            label="Sofort"></v-checkbox>
              </v-col>
              <v-col cols="7" sm="3">
                <date-time-picker :disabled="inputCodeNoEndTime" label="Endzeit des Gutscheins"
                                  v-model="inputCodeEndTime"></date-time-picker>
              </v-col>
              <v-col cols="5" sm="3">
                <v-checkbox class="mt-0" dense v-model="inputCodeNoEndTime"
                            label="Unbegrenzt"></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red" text @click="discountDialog = false">Abbrechen</v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="!codeFormValidation" color="green" text @click="submitDiscountCode">Speichern</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-layout align-center justify-center class="pt-12 pb-9" v-else>
      <v-progress-circular
          indeterminate
          size="85"
          color="primary"
      ></v-progress-circular>
    </v-layout>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import MenuEditor from "@/components/MenuEditor";
import DateTimePicker from "@/components/DateTimePicker";
import Vue from 'vue'
import {mdiPlus, mdiCheck, mdiClose, mdiInformation, mdiDelete, mdiExclamation, mdiUndoVariant} from '@mdi/js'
import OpeningTimeChanger from "@/components/Admin/OpeningTimeChanger";
import {showCouponSettings} from "@/restaurantConfig";


export default {
  name: "manage-settings",
  components: {OpeningTimeChanger, DateTimePicker, MenuEditor},
  data() {
    return {
      mdiPlus,
      mdiCheck,
      mdiClose,
      mdiInformation,
      mdiDelete,
      mdiExclamation,
      mdiUndoVariant,
      showCouponSettings,
      activeTab: undefined,
      zipDialog: false,
      inputZipCode: {},
      zipAction: undefined,
      chosenPaymentOptions: [],
      discountDialog: false,
      editableDiscount: {},
      discountAction: undefined,
      inputCodeName: '',
      inputCodeMaxUses: 1,
      inputCodeUnlimitedUses: false,
      inputCodeStartTime: undefined,
      inputCodeNoStartTime: true,
      inputCodeEndTime: undefined,
      inputCodeNoEndTime: false,
      inputCodeOncePerUser: true,
      inputCodePercentOff: 0,
      inputCodeCashDiscount: 0,
      inputCodeType: 'percent',
      inputCodeMinimumOrder: 0,
    }
  },
  created() {
    if (this.hasCheckedInfo) {
      if (!this.hasCheckedDiscountCodes) {
        this.fetchDiscountCodes()
      }
    }
  },
  watch: {
    hasCheckedInfo(val) {
      if (val) {
        if (!this.hasCheckedDiscountCodes) {
          this.fetchDiscountCodes()
        }
      }
    },
    inputCodeCashDiscount(value) {
      if (Number(this.inputCodeMinimumOrder) < Number(value)) {
        this.inputCodeMinimumOrder = value
      }
    }
  },
  methods: {
    ...mapActions({
      toggleRestaurantAvailability: 'restaurantStore/toggleRestaurantAvailability',
      editMinimumOrder: 'restaurantStore/editMinimumOrder',
      updateZipCodes: 'restaurantStore/updateZipCodes',
      setLastUpdated: 'menuStore/setLastUpdated',
      fetchDiscountCodes: 'restaurantStore/fetchDiscountCodes',
      addDiscountCode: 'restaurantStore/addDiscountCode',
      disableDiscountCode: 'restaurantStore/disableDiscountCode',
      enableDiscountCode: 'restaurantStore/enableDiscountCode',
      deleteDiscountCode: 'restaurantStore/deleteDiscountCode'
    }),
    handleReloadMenu() {
      if (confirm('Falls Kunden Probleme mit der Speisekarte haben kann diese Aktion helfen. Alle momentanen Warenkörbe werden gelöscht. Fortfahren?')) {
        this.setLastUpdated()
      }
    },
    deleteZipCode(payload) {
      let zipCodes = [...this.zipCodes]
      let index = zipCodes.findIndex(x => (x.zipCode == payload.zipCode && x.deliveryFee == payload.deliveryFee))
      zipCodes.splice(index, 1)
      this.updateZipCodes(zipCodes)
    },
    saveZipCode(payload) {
      if (!payload.deliveryFee) {
        payload.deliveryFee = 0
      }
      if (!payload.minimumOrder) {
        payload.minimumOrder = 0
      }
      let zipCodes = [...this.zipCodes]
      if (this.zipAction == "add") {
        zipCodes.push(payload)
        this.updateZipCodes(zipCodes).then(() => {
          this.zipDialog = false
        })
      }
    },
    clearInputWindow() {
      this.inputCodeName = ''
      this.inputCodeMaxUses = 1
      this.inputCodeUnlimitedUses = false
      this.discountAction = undefined
      this.inputCodeStartTime = undefined
      this.inputCodeNoStartTime = true
      this.inputCodeEndTime = undefined
      this.inputCodeNoEndTime = false
      this.inputCodeOncePerUser = true
      this.inputCodePercentOff = 0
      this.inputCodeCashDiscount = 0
      this.inputCodeType = 'percent'
      this.inputCodeMinimumOrder = 0

      this.editableDiscount = {}
    },
    handleNewDiscountCode() {
      this.clearInputWindow()
      this.discountAction = 'add'
      Vue.nextTick().then(() => {
        this.discountDialog = true
      })
    },
    generateDiscountCode() {
      let result = '';
      let characters = 'ABCDEFGHIJKLMNPQRSTUVWXYZ123456789';
      for (let i = 0; i < 12; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      this.inputCodeName = result
    },
    createCodeInfoString(code) {
      let string = ''
      if (code.maxUses) {
        string += 'Maximale Anzahl der Benutzungen: ' + code.maxUses
      } else {
        string += 'Unlimiterte Benutzung'
      }
      string += '\n'
      if (code.validFrom) {
        string += ', Benutzbar ab: ' + code.validFrom.toLocaleString() + '\n'
      }
      if (code.validUntil) {
        string += ', Benutzbar bis: ' + code.validUntil.toLocaleString() + '\n'
      }
      return string
    },
    submitDiscountCode() {
      if (this.discountAction == 'add') {
        console.log(this.discountCodes)
        if(this.discountCodes.some(code => code.code == this.inputCodeName.toUpperCase())){
          window.alert('Es gibt bereits einen Code mit diesem Namen!')
          return
        }
        let objectToSend = {
          code: this.inputCodeName.toUpperCase(),
          disabled: false
        }
        if (this.inputCodeType == 'percent') {
          objectToSend.percentOff = parseInt(this.inputCodePercentOff)
          objectToSend.type = 'percent'
        } else if (this.inputCodeType == 'cash') {
          objectToSend.cashDiscount = this.inputCodeCashDiscount
          objectToSend.type = 'cash'
        }
        if (!this.inputCodeUnlimitedUses) {
          objectToSend.maxUses = this.inputCodeMaxUses
        }
        if (!this.inputCodeNoStartTime) {
          objectToSend.validFrom = this.inputCodeStartTime
        }
        if (!this.inputCodeNoEndTime) {
          objectToSend.validUntil = this.inputCodeEndTime
        }
        if (this.inputCodeOncePerUser && (this.inputCodeUnlimitedUses || (this.inputCodeMaxUses && this.inputCodeMaxUses > 1))) {
          objectToSend.oncePerUser = true
        }
        objectToSend.minimumOrder = this.inputCodeMinimumOrder
        this.addDiscountCode(objectToSend).then(() => {
          this.clearInputWindow()
          this.discountDialog = false
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      zipCodes: 'restaurantStore/zipCodes',
      hasCheckedInfo: 'restaurantStore/hasCheckedInfo',
      acceptingOrders: 'restaurantStore/acceptingOrders',
      hasCheckedDiscountCodes: 'restaurantStore/hasCheckedDiscountCodes',
      discountCodes: 'restaurantStore/discountCodes',
      isCurrentTimeInOpeningHours: 'restaurantStore/isCurrentTimeInOpeningHours'
    }),
    codeFormValidation() {
      if (this.inputCodeName.length >= 5 &&
          (this.inputCodeMaxUses > 0 || this.inputCodeUnlimitedUses) &&
          (this.inputCodeStartTime || this.inputCodeNoStartTime) &&
          (this.inputCodeEndTime || this.inputCodeNoEndTime) &&
          this.inputCodeMinimumOrder >= 0 &&
          this.inputCodeType &&
          (this.inputCodeType != 'percent' || (this.inputCodePercentOff > 0 && this.inputCodePercentOff < 100)) &&
          (this.inputCodeType != 'cash' || (this.inputCodeCashDiscount > 0 && this.inputCodeMinimumOrder >= this.inputCodeCashDiscount))
      ) {
        return true
      } else return false
    },
    bannerInfos(){
      let text = ""
      let color = ""
      if(this.acceptingOrders && this.isCurrentTimeInOpeningHours) {
         text = 'Das Restaurant ist momentan OFFEN.'
        color ="light-green lighten-3"
      }else  if (this.acceptingOrders && !this.isCurrentTimeInOpeningHours) {
         text =  'Das Restaurant hat zu wegen den Öffnungszeiten.'
        color= "yellow   lighten-3"
      } else {
         text = 'Das Restaurant ist momentan GESCHLOSSEN'
        color= "deep-orange   lighten-3"
      }
      return [text, color]
    }
  },
}
</script>

<style scoped>

</style>