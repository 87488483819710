<template>
  <div>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="title">Öffnungszeiten</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-col cols="12" v-for="weekday in weekdays" :key="weekday.day">
              <v-card class="px-0 mx-0">
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-row no-gutters>
                        <v-col cols="10">
                          <div class="subtitle-1">
                            {{ weekday.name }}
                          </div>
                        </v-col>
                        <v-col cols="2">
                          <v-btn icon @click="dialog = true; chosenWeekday = weekday.day">
                            <v-icon>{{ mdiPlus }}</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12">
                      <v-row no-gutters v-for="openingTime in openingHours.filter(x => x.day == weekday.day)"
                             :key="openingTime.openingTime.toString() + openingTime.closingTime.toString() ">
                        <v-col cols="10">
                          <p>{{ openingTime.openingTime }} - {{ openingTime.closingTime }}</p>
                        </v-col>
                        <v-col cols="2">
                          <v-btn icon @click="deleteOpeningHour(openingTime)">
                            <v-icon dense>{{ mdiDelete }}</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    <v-dialog
        v-model="dialog"
        max-width="800px"
    >
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <p class="title">Von:</p>
              <v-time-picker
                  v-if="dialog"
                  v-model="chosenOpeningTime"
                  format="24hr"
              ></v-time-picker>
            </v-col>
            <v-col cols="12" md="6">
              <p class="title">Bis:</p>
              <v-time-picker
                  v-if="dialog"
                  v-model="chosenClosingTime"
                  :disabled="!chosenOpeningTime"
                  :min="chosenOpeningTime"
                  format="24hr"
              ></v-time-picker>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="dialog = false">
            Abbrechen
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="addOpeningHour">
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mdiPlus, mdiCheck, mdiClose, mdiInformation, mdiDelete, mdiExclamation} from '@mdi/js'
import {mapActions, mapGetters} from "vuex";

export default {
  name: "OpeningTimeChanger",
  data() {
    return {
      mdiPlus: mdiPlus,
      mdiCheck: mdiCheck,
      mdiClose: mdiClose,
      mdiInformation: mdiInformation,
      mdiDelete: mdiDelete,
      mdiExclamation: mdiExclamation,
      weekdays: [
        {
          name: 'Montag',
          day: 1
        },
        {
          name: 'Dienstag',
          day: 2
        },
        {
          name: 'Mittwoch',
          day: 3
        },
        {
          name: 'Donnerstag',
          day: 4
        },
        {
          name: 'Freitag',
          day: 5
        },
        {
          name: 'Samstag',
          day: 6
        },
        {
          name: 'Sonntag',
          day: 0
        },
      ],
      dialog: false,
      chosenOpeningTime: undefined,
      chosenClosingTime: undefined,
      chosenWeekday: undefined,
    }
  },
  methods: {
    ...mapActions({
      updateOpeningHours: 'restaurantStore/updateOpeningHours',
    }),
    addOpeningHour() {
      let newOpeningHours = JSON.parse(JSON.stringify(this.openingHours))
      newOpeningHours.push({
        day: this.chosenWeekday,
        openingTime: this.chosenOpeningTime,
        closingTime: this.chosenClosingTime
      })
      this.updateOpeningHours(newOpeningHours).then(() => {
        this.chosenWeekday = undefined
        this.chosenOpeningTime = undefined
        this.chosenClosingTime = undefined
        this.dialog = false
      })
    },
    deleteOpeningHour(toDelete) {
      let newOpeningHours = JSON.parse(JSON.stringify(this.openingHours))

      let index = newOpeningHours.findIndex(x =>
          (x.openingTime.localeCompare(toDelete.openingTime) == 0 && x.closingTime.localeCompare(toDelete.closingTime) == 0 && x.day == toDelete.day)
      )
      newOpeningHours.splice(index, 1)
      this.updateOpeningHours(newOpeningHours)
    }
  },
  computed: {
    ...mapGetters({
      openingHours: 'restaurantStore/openingHours',
    }),
  }
}
</script>

<style scoped>

</style>