<template>
    <v-dialog v-model="dialog" width="600px" persistent>
        <template v-slot:activator="{ on }">
            <v-text-field
                    :disabled="disabled"
                    :label="label"
                    :value="dateTimeObject ? dateTimeObject.toString() : ''"
                    v-on="on"
                    readonly
                    dense
            >
            </v-text-field>
        </template>

        <v-card>
            <v-card-text class="px-0 py-0">
                <v-tabs fixed-tabs v-model="activeTab">
                    <v-tab key="calendar">
                        <slot name="dateIcon">
                            <v-icon>{{ mdiCalendar }}</v-icon>
                        </slot>
                    </v-tab>
                    <v-tab key="timer">
                        <slot name="timeIcon">
                            <v-icon>{{ mdiClock }}</v-icon>
                        </slot>
                    </v-tab>
                    <v-tab-item key="calendar">
                        <v-date-picker
                                v-model="dateInput"
                                @input="activeTab = 1"
                                full-width>
                        </v-date-picker>
                    </v-tab-item>
                    <v-tab-item key="timer">
                        <v-time-picker
                                ref="timer"
                                class="v-time-picker-custom"
                                v-model="timeInput"
                                format="24hr"
                                full-width
                        ></v-time-picker>
                    </v-tab-item>
                </v-tabs>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn :disabled="!timeInput || !dateInput" text @click="handleSubmit">Fertig</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mdiCalendar, mdiClock} from '@mdi/js'

    export default {
        name: "DateTimePicker",
        props: {
            disabled: Boolean,
            value: [Date, String],
            label: String,
        },
        data() {
            return {
                mdiCalendar:mdiCalendar,
                mdiClock:mdiClock,
                activeTab: 0,
                dateInput: undefined,
                timeInput: undefined,
                dialog: false,
            }
        },
        mounted() {
            let dateObj = typeof this.value === 'string' ? new Date(this.value) : this.value
            this.timeInput = dateObj ? dateObj.getHours() + ":" + dateObj.getMinutes() : undefined
            this.dateInput = dateObj || undefined
            this.$emit('input', dateObj)
        },
        computed: {
            dateTimeObject() {
                if(this.dateInput && this.timeInput){
                    return new Date(this.dateInput + ' ' + this.timeInput)
                } else return undefined
            },
        },
        methods: {
            handleSubmit() {
                this.resetPicker()
                this.$emit('input', this.dateTimeObject)
            },
            resetPicker() {
                this.dialog = false
                this.activeTab = 0
                if (this.$refs.timer) {
                    this.$refs.timer.selectingHour = true
                }
            },
        },
        watch: {
            value: function(newDate) {
                if(newDate){
                    let dateObj = typeof newDate === 'string' ? new Date(newDate) : newDate
                    let hour = String(dateObj.getHours()).length < 2 ? '0' + String(dateObj.getHours()) : String(dateObj.getHours())
                    let minute = String(dateObj.getMinutes()).length < 2 ? '0' + String(dateObj.getMinutes()) : String(dateObj.getMinutes())
                    this.timeInput =hour + ":" + minute
                    let month = String(dateObj.getMonth()).length < 2 ? '0' + String(dateObj.getMonth()) : String(dateObj.getMonth())
                    let day = String(dateObj.getDate()).length < 2 ? '0' + String(dateObj.getDate()) : String(dateObj.getDate())
                    this.dateInput = dateObj.getFullYear() + "-" + month + "-" + day
                } else {
                    this.timeInput = undefined
                    this.dateInput = undefined
                }

            }
        }
    }
    </script>